@if (header()) {
  <div
    class="flex justify-between px-4 mb-4"
    [ngClass]="{ 'flex-col md:flex-row': isResponsiveHeaderSlot() }"
  >
    <h3 class="m-0 text-xl font-extrabold text-black md:text-2xl">{{ headerText() }}</h3>
    <ng-content select="[slot=header]"></ng-content>
  </div>
}

<div class="px-4 card-body">
  <ng-content select="[slot=body]"></ng-content>
</div>
