import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-dashboard-card',
  standalone: true,
  imports: [NgClass],
  templateUrl: './dashboard-card.component.html',
  styleUrl: './dashboard-card.component.scss',
})
export class DashboardCardComponent {
  headerText = input<string>('Header text');
  header = input<boolean>(true);
  isResponsiveHeaderSlot = input<boolean>(false);
}
